<!--eslint-disable-->

<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-50"
      :to="{ name: 'offr/editWebsite' }"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <b-card>
      <h4>{{ $t('offer.website.Yours') }}</h4>

      <b-table
        :items="webs"
        class="table-wrap-words mt-1 rounded"
        :fields="fields"
        striped
        responsive
        :busy="loading"
        show-empty
      >
        <!--    Head    -->
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <!--    Name    -->
        <template #cell(name)="data">
          <router-link
            v-b-tooltip.hover.v-primary
            :to="{ name: 'offr/editWebsite', params: { websiteId: data.item.id } }"
            class="text-nowrap"
            :title="data.item.name"
          >
            <span
              v-if="data.item.name"
              class="text-nowrap"
            >
              {{ data.item.name | truncate(24, '...') }}
            </span>
            <span
              v-else
              class="text-nowrap"
            >
              {{ $t('NoName') }}
            </span>
          </router-link>
        </template>

        <!--    Preview    -->
        <template #cell(preview)="{ value }">
          <b-button
            variant="flat-warning"
            class="btn-icon px-25 py-25"
            :href="`${location()}/offer/preview/${value}`"
            target="_blank"
          >
            <feather-icon
              icon="EyeIcon"
            />
          </b-button>
        </template>

        <!--    Is active    -->
        <template #cell(isActive)="{ value }">
          <b-button
            v-b-tooltip.hover
            :variant="value ? 'flat-success': 'flat-danger'"
            :title="$t(value ? 'Active' : 'InActive')"
            class="btn-icon px-25 py-25"
          >
            <feather-icon
              :icon="value ? 'CheckIcon' : 'XIcon'"
            />
          </b-button></template>

        <!--    CreatedBy    -->
        <template #cell(createdBy)="{ value }">
          <avatar
            :user="value"
            :to="{ name: 'user', params: { userId: value.id } }"
          />
        </template>

        <!--    Action    -->
        <template #cell(action)="{ item, index }">
          <b-dropdown
            variant="link"
            class="dropdown-icon-wrapper text-decoration-none"
            no-caret
          >
            <template
              #button-content
              class="sr-only"
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle"
              />
            </template>

            <!--    Action: Duplicate    -->
            <b-dropdown-item @click="duplicate(item)">
                <dropdown-item-label
                        icon="CopyIcon"
                        :label="$t('Duplicate')"
                />
            </b-dropdown-item>
              <!--    Action: Duplicate    -->
            <b-dropdown-item @click="deleteItem(item, index)">
                <dropdown-item-label
                        icon="TrashIcon"
                        :label="$t('Delete')"
                />
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--      Empty      -->
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { CREATE_WEBSITE, GET_WEBSITES } from '@/@constants/mutations'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    loading: false,

    fields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'preview', label: 'Preview', visible: true },
      { key: 'isActive', label: 'isActive', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'updatedAt', label: 'UpdatedAt', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    webs: [],
    newWebsite: {},
  }),
  mounted() {
    this.getFromServer()
  },
  methods: {
    location() {
      return window.location.port || window.location.origin.toString() === 'https://demo.saleswizardapp.com' ? 'https://d1.saleswizardapp.com' : window.location.origin
    },
    getFromServer() {
      this.loading = true

      this.getWebsites()
    },

    async duplicate(item) {
      this.loading = true

      await this.getWebsite(item.id)

      await this.pushWebsite()
    },

    async getWebsite(id) {
      await this.$store.dispatch('websites/GET_WEBSITE_COPY', id)
        .then(res => {
          const web = res.data.item

          this.newWebsite = web
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    getWebsites() {
      this.webs = []

      this.$store.dispatch(`websites/${GET_WEBSITES}`)
        .then(res => {
          const websites = res.data.items

          websites.forEach(el => {
            if (!!el.translations && el.translations.length > 0) {
              this.webs.push({
                id: el.id,
                isActive: el.active,
                name: el.name,
                preview: el.id,
                createdAt: el.createdAt,
                updatedAt: el.updatedAt,
                createdBy: el.createdBy,
              })
            }
          })

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    async deleteItem(item, index) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.delete('1/settings/offerWebTemplate', { data: JSON.stringify([item.id]) })

        this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
        this.$delete(this.webs, index)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async pushWebsite() {
      const { newWebsite } = this

      const copyToSystem = true
      if (copyToSystem && this.checkRequiredHostnameFragments()) {
        newWebsite.banner = null
        newWebsite.companyBanner = null
        newWebsite.contactAvatar = null
        newWebsite.favicon = null
        newWebsite.logo = null
        newWebsite.cooperationFiles = []

        newWebsite.offerWebTemplatePortfolioItems = (newWebsite.offerWebTemplatePortfolioItems || []).map(e => ({ ...e, file: null })).filter(Boolean)
        newWebsite.offerWebTemplateOpinionItems = (newWebsite.offerWebTemplateOpinionItems || []).map(e => ({ ...e, avatar: null })).filter(Boolean)

        this.loading = false
        console.log(newWebsite)
        return
      }
      newWebsite.banner = newWebsite.banner?.token || null
      newWebsite.companyBanner = newWebsite.companyBanner?.token || null
      newWebsite.contactAvatar = newWebsite.contactAvatar?.token || null
      newWebsite.favicon = newWebsite.favicon?.token || null
      newWebsite.logo = newWebsite.logo?.token || null
      newWebsite.cooperationFiles = (newWebsite.cooperationFiles || []).map(e => e?.token).filter(Boolean)

      newWebsite.offerWebTemplatePortfolioItems = (newWebsite.offerWebTemplatePortfolioItems || []).map(e => ({ ...e, file: e.file?.token || null })).filter(Boolean)
      newWebsite.offerWebTemplateOpinionItems = (newWebsite.offerWebTemplateOpinionItems || []).map(e => ({ ...e, avatar: e.avatar?.token || null })).filter(Boolean)

      /* eslint-disable */

      this.$store.dispatch(`websites/${CREATE_WEBSITE}`, newWebsite)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.offerWebsiteDuplicated'), this.$i18n.t('success.duplicated'))

          this.getWebsites()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
